export const leftMenu = {
  items: [
    {
      name: "Dashboard",
      url: "/home",
      icon: "fa fa-dashboard",
      isHeaderMenu: true,
    },
    {
      name: "Creators",
      url: "/creators",
      icon: "icon-user",
      isHeaderMenu: true,
    },
    {
      name: "Subscribers",
      url: "/subscribers",
      icon: "icon-user-follow",
      isHeaderMenu: true,
    },
    {
      name: "Leaderboard",
      url: "/leaderboard",
      icon: "fa fa-dollar",
      isHeaderMenu: true,
    },
    {
      name: "Live Events",
      url: "/live-events",
      icon: "fa fa-calendar",
      isHeaderMenu: true,
    },
    {
      name: "PPV",
      url: "/pay-per-view",
      icon: "fa fa-video-camera",
      isHeaderMenu: true,
    },
    {
      name: "Shout Out",
      url: "/shoutout",
      icon: "fa fa-video-camera",
      isHeaderMenu: true,
    },
    {
      name: "Vault",
      url: "/vault",
      icon: "fa fa-lock",
      isHeaderMenu: true,
    },
    // {
    //   name: "Courses",
    //   url: "/courses",
    //   icon: "fa fa-exchange",
    //   isHeaderMenu: true,
    // },
    {
      name: "Transactions",
      url: "/transactions",
      icon: "fa fa-exchange",
      isHeaderMenu: true,
    },
    {
      name: "Payouts",
      url: "/payouts",
      icon: "fa fa-dollar",
      isHeaderMenu: true,
    },
    // {
    //   name: "Content Moderation",
    //   url: "/content-moderation",
    //   icon: "icon-pencil",
    //   isHeaderMenu: true
    // },
    {
      name: "Invitations",
      url: "/invitations",
      icon: "fa fa-plus",
      isHeaderMenu: true,
    },
    {
      name: "Creator Requests",
      url: "/creator-requests",
      icon: "fa fa-plus",
      isHeaderMenu: true,
    },
    {
      name: "Admin Accounts",
      url: "/admin-accounts",
      icon: "fa fa-black-tie",
      isHeaderMenu: true,
    },
    {
      name: "Agency",
      url: "/agency",
      icon: "fa fa-black-tie",
      isHeaderMenu: true,
    },
    // {
    //   name: "Orders",
    //   url: "/orders",
    //   icon: "fa fa-trello",
    //   isHeaderMenu: true,
    // },
    // {
    //   name: "Products",
    //   url: "/products",
    //   icon: "fa fa-th",
    //   isHeaderMenu: true,
    // },
    // {
    //   name: "Publish Products",
    //   url: "/Publish-products",
    //   icon: "fa fa-th",
    //   isHeaderMenu: true,
    // },
    // {
    //   name: "Settings",
    //   url: "/settings",
    //   icon: "icon-settings",
    //   isHeaderMenu: true,
    // },
  ],
};
